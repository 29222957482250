<template>
    <div>
        <div class="text-center pa-10">
            <div>
                <v-icon
                    color="secondary"
                    size="56"
                >mdi-account-circle</v-icon>
            </div>
            <div>
                <h2>{{ $store.state.user.name }}</h2>
                <div class="text-subtitle-1">{{ $store.state.user.store }}</div>
            </div>
        </div>
        <v-list dense>            
            <v-list-item-group>
                <v-list-item @click="resetPasswordDialog = true">
                    <v-list-item-content>
                        <v-list-item-title 
                            style="height:40px;line-height:40px"
                            v-text="lang('change_password')"
                            class="text-h6"
                        ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
                <v-list-item @click="logout">
                    <v-list-item-content>
                        <v-list-item-title 
                            style="height:40px;line-height:40px"
                            v-text="lang('logout')"
                            class="text-h6"
                        ></v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list-item-group>
        </v-list>
        <v-dialog
            v-model="resetPasswordDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary darken-2"
                >
                    <v-btn
                        icon
                        dark
                        @click="resetPasswordDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{lang('change_password')}}</v-toolbar-title>
                    <v-spacer></v-spacer>                
                </v-toolbar>

                <v-form
                    ref="loginForm"
                    v-model='isValid'
                >

                    <v-container>
                        <v-row>
                            <v-col>
                                <v-text-field
                                    v-model="oldPassword"
                                    :label="lang('old_password')"                                    
                                    :rules="validationRules.oldPassword"
                                    required
                                    outlined
                                    type="password"
                                ></v-text-field>

                                <v-text-field
                                    v-model="newPassword"
                                    :label="lang('new_password')"                                    
                                    :rules="validationRules.newPassword"
                                    required
                                    type="password"
                                    outlined
                                ></v-text-field>

                                <v-text-field
                                    v-model="confirmPassword"
                                    :label="lang('confirm_password')"                                    
                                    :rules="validationRules.confirmPassword"
                                    required
                                    type="password"
                                    outlined
                                ></v-text-field>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col
                                class="text-right"
                            >
                                <v-btn
                                    color="success"
                                    @click="submitPassword"
                                    :disabled="!isValid"
                                    :loading="loading"
                                >
                                    {{ lang('button_confirm') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-container>

                </v-form>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            resetPasswordDialog: false,
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            isValid: false,
            loading: false,
            validationRules: {
                oldPassword : [
                    v   =>  !!v || this.lang('old_password_validation')
                ],
                newPassword : [
                    v   =>  !!v || this.lang('new_password_validation'),
                    v   =>  v.length > 5 || this.lang('min_password_validation')
                ],
                confirmPassword : [
                    v   =>  !!v || this.lang('confirm_password_validation'),
                    v   =>  v === this.newPassword || this.lang('confirm_password_validation')
                ],
            }
        }
    },
    methods: {
        logout() {
            this.$store.dispatch('logout');

            this.$router.push({'name' : 'Login'});
        },
        async submitPassword() {
            this.setLoading(true);
            this.loading = true;
            try {                
                const response = await axios.post('/store/changepassword', {
                    old_password: this.oldPassword,
                    password: this.newPassword
                });

                const result = response.data;

                if (result.status == 1)
                    throw result.message;
                    
                this.resetPasswordDialog = false;

                this.$store.commit('setPopup', {
                    msg: 'success',
                    type: this.popup_type.SUCCESS
                });
            } catch (e) {
                this.$store.commit('setPopup', {
                    msg: this.lang(e) ? this.lang(e) : e,
                    type: this.popup_type.ERROR
                });
            } finally {
                this.setLoading(false);
                this.loading = false;
            }
        }
    }
}
</script>