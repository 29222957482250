import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import axios from 'axios';
import VueJWT from 'vuejs-jwt'
import VueCookies from 'vue-cookies'

import langEng from './lang/eng.json';
import langTh from './lang/th.json';

const langObject = {
  en: langEng,
  th: langTh
};

axios.defaults.baseURL = process.env.VUE_APP_ROOT_API

Vue.use(VueJWT, {})
Vue.use(VueCookies)

router.beforeResolve((to, from, next) => {  
  if (to.meta.auth) {
    if (store.getters['isLogin'])
      return next();    
    return next('/login');
  }

  return next();
});

Vue.mixin({
  data() {
    return {
      currentLang: 'en',
      popup_type: {
        ERROR: 'popup_error',
        INFO: 'popup_info',
        CONFIRM: 'popup_confirm',
        SUCCESS: 'popup_success'
      }
    }
  },
  created() {
    this.currentLang = Vue.$cookies.get('lang') ? Vue.$cookies.get('lang') : 'en';
  },
  methods: {
    lang(text, options = []) {
      let currentText = langObject[this.currentLang] ? langObject[this.currentLang][text] : langObject.en[text];

      for (let i = 0; i < options.length; i++) {
        currentText = currentText.replace(":n", options[i]);
      }      

      return currentText;
    },
    clearPopup() {
      store.commit('clearPopup');
    },
    getPopupColor() {
      return store.getters['getPopupColor'];
    },
    setLoading(bool) {
      store.commit('setLoading', bool);
    },
    isAdmin() {
      return store.getters['isAdmin'];
    },
    setLang(lang) {      
      this.currentLang = lang;

      Vue.$cookies.set('lang', lang, -1);
    }
  }
});

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
