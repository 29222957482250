<template>
    <div 
        class="d-flex pl-6 pr-6"
        style="height: 100%"
    >
        <v-container
            class="ma-auto"
            style="max-width: 600px;"
        >   
            <v-form
                v-model="isValid"
            >
                <v-row>
                    <h4>{{ lang('forget_password_title') }}</h4>
                </v-row>
                <v-row>
                    <v-col>
                        <v-text-field
                            label="Username"
                            v-model="username"
                            required
                        ></v-text-field>
                    </v-col>
                </v-row>        
                <v-row>
                    <v-col class="text-right">
                        <v-btn
                            color="red white--text mr-2"                        
                            @click="$router.push({name: 'Login'})"
                        >
                            {{ lang('button_cancel') }}
                        </v-btn>
                        <v-btn
                            color="success"
                            @click="requestPIN"
                        >
                            {{ lang('button_next') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-form>                     
        </v-container>

        <v-dialog
            v-model="resetDialog"
            transition="dialog-bottom-transition"
            max-width="600"     
        >
            <v-form
                v-model="isPINValid"
            >
                <v-card>
                    <v-card-title class="text-h5 red white--text">
                    {{ lang('input_pin_title') }}
                    </v-card-title>

                    <v-card-text
                        class="text-h5 mt-2"
                    >
                        {{ lang('input_pin_body') }}

                        <v-text-field
                            label="5 Digits PIN"
                            :rules="[
                                value => !!value || 'Required.',
                                value => (value && value.length == 5) || lang('input_pin_length_validation'),
                            ]"
                            hide-details="auto"
                            :counter="5"
                            v-model="pin"
                            outlined
                        ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                            color="red white--text"                        
                            @click="resetDialog = false"
                        >
                            {{ lang('button_cancel') }}
                        </v-btn>
                        <v-btn
                            color="primary darken-2"                        
                            @click="confirmPIN"
                            :disabled="!isPINValid"
                        >
                            {{ lang('button_confirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>

        <v-dialog
            v-model="newPasswordDialog"
            transition="dialog-bottom-transition"
            max-width="600"     
        >
            <v-form
                v-model="isPasswordValid"
            >
                <v-card>
                    <v-card-title class="text-h5 red white--text">
                    {{lang('change_password')}}
                    </v-card-title>

                    <v-card-text
                        class="text-h5 mt-2"
                    >
                        <v-text-field
                            v-model="newPassword"
                            :label="lang('new_password')"                                    
                            :rules="validationRules.newPassword"
                            required
                            type="password"
                            outlined
                        ></v-text-field>

                        <v-text-field
                            v-model="confirmPassword"
                            :label="lang('confirm_password')"                                    
                            :rules="validationRules.confirmPassword"
                            required
                            type="password"
                            outlined
                        ></v-text-field>
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                        <v-btn
                            color="red white--text"                        
                            @click="resetDialog = false"
                        >
                            {{ lang('button_cancel') }}
                        </v-btn>
                        <v-btn
                            color="primary darken-2"                        
                            @click="changePassword"
                            :disabled="!isPasswordValid"
                        >
                            {{ lang('button_confirm') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>    
</template>
<script>
import axios from 'axios'
export default {
    data() {
        return {
            username: '',
            pin: '',
            reset_token: '',
            confirmPassword: '',
            newPassword: '',
            isValid: false,
            isPINValid: false,
            isPasswordValid: false,
            resetDialog: false,
            newPasswordDialog: false,
            validationRules: {
                username : [
                    v   =>  !!v || this.lang('username_validation')
                ],
                newPassword : [
                    v   =>  !!v || this.lang('new_password_validation'),
                    v   =>  v.length > 5 || this.lang('min_password_validation')
                ],
                confirmPassword : [
                    v   =>  !!v || this.lang('confirm_password_validation'),
                    v   =>  v === this.newPassword || this.lang('confirm_password_validation')
                ],
            }
        }
    },
    methods: {
        async requestPIN() {
            this.setLoading(true);
            try {                
                const response = await axios.post('generateResetPIN', {
                    username: this.username
                });

                const data = response.data;
                if (data.status == 1)
                    throw data.message;

                this.setLoading(false);   
                
                
                this.resetDialog = true;
            } catch (e) {
                this.setLoading(false);

                this.$store.commit('setPopup', {
                    msg: this.lang(e) ? this.lang(e) : e,
                    type: this.popup_type.ERROR
                });
            }            
        },
        async confirmPIN() {
            this.setLoading(true);
            try {                
                const response = await axios.post('verifyPIN', {
                    username: this.username,
                    pin: this.pin
                });

                const data = response.data;
                if (data.status == 1)
                    throw data.message;

                this.setLoading(false);   
                
                this.reset_token = data.data;
                
                this.resetDialog = false;
                this.newPasswordDialog = true;
            } catch (e) {
                this.setLoading(false);

                this.$store.commit('setPopup', {
                    msg: this.lang(e) ? this.lang(e) : e,
                    type: this.popup_type.ERROR
                });
            }
        },
        async changePassword() {
            this.setLoading(true);
            try {                
                const response = await axios.post('resetPassword', {
                    username: this.username,
                    reset_token: this.reset_token,
                    password: this.newPassword
                });

                const data = response.data;
                if (data.status == 1)
                    throw data.message;

                this.setLoading(false);   
                
                
                this.newPasswordDialog = false;
                this.$router.push({name: 'Login'});
            } catch (e) {
                this.setLoading(false);

                this.$store.commit('setPopup', {
                    msg: this.lang(e) ? this.lang(e) : e,
                    type: this.popup_type.ERROR
                });
            }
        }
    }
}
</script>