<template>
    <v-container>
        <v-row class="secondary lighten-1 primary--text font-weight-bold">
            <v-col>
                <v-icon large color="primary" class="mr-2">mdi-car-brake-parking</v-icon>{{ lang("footer_menu_spaces") }}        
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-text class="text-center pa-8">
                        <h3 class="font-weight-bold text-h5">{{ lang('available_spaces') }}</h3>
                        <h1 class="primary--text text-h2 font-weight-bold mt-4">{{ spaces }}</h1>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                {{ lang('date') }} : {{ date }}
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                {{ lang('time') }} : {{ time }}
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
import axios from 'axios';
export default {
    data() {
        return {
            date: null,
            time: null,
            spaces: null
        }
    },
    async created() {
        this.setLoading(true);
        const response = await axios.get('store/spaces');

        const data = response.data;

        this.date = data.date;
        this.time = data.time;
        this.spaces = data.spaces;


        this.setLoading(false);
    }
}
</script>