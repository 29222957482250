<template>
    <v-container class="pt-0 pl-0 pr-0">        
        <v-app-bar
            app
            color="primary"
            dark
            height="125px"
        >
            <v-img
                contain
                height="100%"
                max-height="50px"
                src="@/assets/images/logo.png">
            </v-img>
        </v-app-bar>

        <div 
            style="max-width:600px; margin-bottom: 60px;"
            class="ml-auto mr-auto"
        >
            <router-view/>
        </div>        

        <v-footer
            v-bind="{
                fixed: true
            }"
            :padless="true"
            max-width="600"
            min-height="60"
            class="ml-auto mr-auto"
        >
            <div
                class="d-flex flex-grow-1"
            >
                <footerbtn
                    icon="home"
                    :text="lang('footer_menu_home')"
                    :isSelected="getMenuIndex == 0"
                    @click="$router.push({name: 'Home'})"
                ></footerbtn>
                <footerbtn
                    icon="car-brake-parking"
                    :text="lang('footer_menu_spaces')"
                    :isSelected="getMenuIndex == 1"
                    @click="$router.push({name: 'Spaces'})"
                ></footerbtn>
                <footerbtn
                    v-if="isAdmin()"
                    icon="text-box"
                    :text="lang('footer_menu_history')"
                    :isSelected="getMenuIndex == 2"
                    @click="$router.push({name: 'History'})"
                ></footerbtn>
                <footerbtn
                    icon="cog-outline"
                    :text="lang('footer_menu_settings')"
                    :isSelected="getMenuIndex == 3"
                    @click="$router.push({name: 'Settings'})"
                ></footerbtn>
            </div>            
        </v-footer>
    </v-container>
</template>
<script>
import footerbtn from '../components/footerbtn';
export default {
    data() {
        return {
            menuIndex: 0
        }
    },
    computed: {
        getMenuIndex() {                        
            if (this.$route.matched.some(({ name }) => name === 'Home'))
                return 0;
            if (this.$route.matched.some(({ name }) => name === 'Spaces'))
                return 1;
            if (this.$route.matched.some(({ name }) => name === 'History'))
                return 2;
            if (this.$route.matched.some(({ name }) => name === 'Settings'))
                return 3;
            return null;
        }
    },
    methods: {
        setMenuState(menuIndex) {
            this.menuIndex = menuIndex;
        }
    },
    components: {
        footerbtn
    }
}
</script>