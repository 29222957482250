<template>
  <div>
    <div class="secondary lighten-1 pa-2">
      <v-container>
        <v-row>
          <v-col
            cols="2"
            class="d-flex justify-center align-center"
          >
            <v-icon
              color="secondary"
              size="56"
            >mdi-account-circle</v-icon>
          </v-col>
          <v-col
            cols="10"
          >
            <div>{{ lang('home_name') }} : {{ $store.state.user.name }}</div>
            <div>{{ lang('home_store') }} : {{ $store.state.user.store }}</div>            
            <div>{{ lang('home_time') }} : {{ now }}</div>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <infocard
              icon="star-circle"
              :text="lang('total_granted')"
              :counter="count"
              style="height:100%"
            ></infocard>
          </v-col>
          <v-col>
            <infocard
              icon="clock-time-three"
              :text="lang('total_hours')"
              :counter="sum"
              style="height:100%"
            ></infocard>          
          </v-col>      
        </v-row>
      </v-container>    
    </div>
    <div class="d-flex flex-grow-1 flex-column"
      style="height:100%"
    >
      <div class="d-flex flex-column">
        <v-btn 
          class="d-flex ma-auto mt-3 mb-3"          
          min-height="150"
          min-width="300"
          color="primary"
          @click="showScanner"
        >
          <div
            class="d-flex flex-column"            
          >
            <v-icon
              style="font-size:100px"
            >
              mdi-line-scan
            </v-icon>
            <h3 class="mt-2">scan qr</h3>
          </div>            
        </v-btn>        
        <v-btn 
          class="d-flex ma-auto mt-3 mb-3"          
          min-height="150"
          min-width="300"
          color="primary"
          disabled
        >
          <div
            class="d-flex flex-column"
          >
            <v-icon
              style="font-size:100px"
            >
              mdi-book-open-variant
            </v-icon>
            <h3 class="mt-2">manual</h3>
          </div>            
        </v-btn>       
      </div>      
    </div>
  </div>    
</template>

<script>
import axios from 'axios';
import infocard from '../components/infocard.vue';
export default {
  name: 'Home',
  data() {
    return {
      count: 0,
      sum: 0,
      now: ''
    }
  },
  async created() {
    this.setLoading(true);
    const response = await axios.get('store/summary');

    const data = response.data;

    this.count = data.count;
    this.sum = Math.floor(data.sum / 60);
    this.now = data.now;


    this.setLoading(false);
  },
  methods: {
    showScanner() {      
      this.$router.push({name: 'QRScanner'});
    }
  },
  components: {    
    infocard
  },
}
</script>