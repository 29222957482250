<template>
    <v-container>
        <v-row class="text-center">
            <v-col>
                <div style="max-width: 374px" class="d-flex flex-column justify-center align-center ml-auto mr-auto">
                    <v-card
                    color="secondary darken"
                    class="rounded pa-2"
                    style="width:100%"
                    >                
                        <h4 class="">{{ lang('qrscan_header') }}</h4>
                    </v-card>
                    <div class="arrow-down mt-4"></div>
                    <v-card
                        max-width="374"
                        class="my-auto mt-4"
                    >
                        <qrcode-stream style="width:100%" :camera="camera" @decode="onDecode" @init="onCameraChange"></qrcode-stream>
                    </v-card>                    
                </div>
            </v-col>
        </v-row>
        <v-row>
            <!--
            <v-col class="text-center">
                <v-btn
                    elevation="2"
                    icon
                    @click="toggleLight"
                >
                    <v-icon>{{ light ? 'mdi-flashlight' : 'mdi-flashlight-off' }} </v-icon>
                </v-btn>                
            </v-col>
            -->
            <v-col 
                class="text-center"
                v-if="camera !== 'auto' && camera !== 'off'"
            >
                <v-btn
                    elevation="2"
                    icon
                    @click="switchCamera"
                >
                    <v-icon>{{ camera === 'front' ? 'mdi-camera-rear-variant' : 'mdi-camera-front-variant' }}</v-icon>
                </v-btn>                
            </v-col>          
        </v-row>
        <v-dialog
            v-model="resultDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-container class="pa-6">
                    <v-row>
                        <v-col>
                            <h2 class="primary--text text--darken-2">{{lang('promotion_select_promotion')}}</h2>                            
                        </v-col>
                    </v-row>
                    <v-row v-if="storeInfo && storeInfo.promotions">
                        <v-btn
                            rounded
                            block
                            large
                            :outlined="selectedPromotion == promotion.discount"
                            color="primary darken-2"
                            v-for="(promotion, index) in storeInfo.promotions"
                            :key="'promotion_' + index"
                            class="mt-2 mb-2"
                            @click="selectedPromotion = promotion.discount"
                        >{{ lang('promotion_discount_free_mns', [promotion.discount]) }}</v-btn>
                        <v-btn
                            rounded
                            block
                            large
                            :outlined="selectedPromotion == 'all'"
                            color="primary darken-2"                            
                            class="mt-2 mb-2"
                            @click="selectedPromotion = 'all'"
                        >{{ lang('promotion_discount_free_all_day') }}</v-btn>
                    </v-row>
                    <v-row>
                        <v-col>
                            <h2 class="primary--text text--darken-2">{{lang('promotion_select_recv')}}</h2>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <v-select
                                v-model="selectedReceiver"
                                :items="[
                                    { value: 'customer', text: lang('promotion_select_option_customer') },
                                    { value: 'staff', text: lang('promotion_select_option_staff') },
                                    { value: 'manager', text: lang('promotion_select_option_manager') },                                    
                                ]"
                                :label="lang('promotion_select_recv')"
                                dense
                                outlined
                            ></v-select>

                            <v-text-field
                                v-model="note"
                                :label="lang('promotion_note')"                                                                                                                                
                                outlined
                                counter="250"
                            ></v-text-field>
                        </v-col>                        
                    </v-row>
                    <v-row>
                        <v-col class="text-left">
                            <v-btn
                                text
                                height="50"
                                @click="resultDialog = false"
                            >
                                <v-avatar
                                    color="red"
                                    class="mr-2"
                                >
                                    <v-icon
                                        large
                                        color="white"
                                    >mdi-close</v-icon>
                                </v-avatar>
                                <label class="text-overline">{{ lang('button_cancel') }}</label>
                            </v-btn>                            
                        </v-col>
                        <v-col class="text-right">
                            <v-btn
                                text
                                height="50"
                                @click="showConfirmDialog()"
                                :disabled="!selectedPromotion"
                            >
                                <label class="text-overline mr-2">{{ lang('button_next') }}</label>
                                <v-avatar
                                    color="success"                                    
                                >
                                    <v-icon
                                        large
                                        color="white"
                                    >mdi-check</v-icon>
                                </v-avatar>                                
                            </v-btn> 
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>            
        </v-dialog>
        <v-dialog
            v-model="confirmDialog"
            fullscreen
            hide-overlay
            transition="dialog-bottom-transition"
            v-if="transaction"
        >
            <v-card>
                <v-toolbar
                dark
                color="primary darken-2"
                >
                    <v-btn
                        icon
                        dark
                        @click="confirmDialog = false"
                    >
                        <v-icon>mdi-close</v-icon>
                    </v-btn>
                    <v-toolbar-title>{{lang('confirmation')}}</v-toolbar-title>
                    <v-spacer></v-spacer>                
                </v-toolbar>

                <v-container>
                    <v-row>
                        <v-col>                            
                            <v-img
                                v-if="transaction.sign_in_transaction && transaction.sign_in_transaction.pic_1"
                                :src="transaction.sign_in_transaction.pic_1"
                            ></v-img>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="primary--text text--darken-2 text-right font-weight-bold">ID</v-col>
                        <v-col>: {{transaction.plateNo}}</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="primary--text text--darken-2 text-right font-weight-bold">{{lang('in_date')}}</v-col>
                        <v-col>: {{phpDateTimeToDate(transaction.signInTime)}}</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="primary--text text--darken-2 text-right font-weight-bold">{{lang('in_time')}}</v-col>
                        <v-col>: {{phpDateTimeToTime(transaction.signInTime)}}</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="primary--text text--darken-2 text-right font-weight-bold">{{lang('duration')}}</v-col>
                        <v-col>: {{phpDateDiffInMins(transaction.signInTime)}} Mn(s)</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="primary--text text--darken-2 text-right font-weight-bold">{{lang('store')}}</v-col>
                        <v-col>: {{ storeInfo.storeName }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="primary--text text--darken-2 text-right font-weight-bold">{{lang('discount')}}</v-col>
                        <v-col>: {{ selectedPromotion === 'all' ? lang('promotion_discount_free_all_day') : lang('promotion_discount_free_mns', [selectedPromotion]) }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col class="text-left">
                            <v-btn
                                color="success"
                                @click="confirmStamp"
                            >{{lang('button_confirm')}}</v-btn>
                        </v-col>
                        <v-col class="text-right">
                            <v-btn
                                color="error"
                                @click="cancelStamp"
                            >{{lang('button_cancel')}}</v-btn>
                        </v-col>
                    </v-row>
                </v-container>                                
            </v-card>            
        </v-dialog>
    </v-container>
</template>
<script>
import axios from 'axios'
import { QrcodeStream, QrcodeDropZone, QrcodeCapture } from 'vue-qrcode-reader'
export default {
    data() {
        return {
            isSubmitting: false,
            camera: 'front',
            light: false,
            resultDialog: false,
            confirmDialog: false,
            transaction: null,
            storeInfo: null,
            selectedPromotion: 0,
            selectedReceiver: 'customer',
            note: ''
        }
    },
    methods: {
        async onDecode (decodedString) {
            const oldCamera = this.camera;
            if (!this.isSubmitting) {
                try {
                    this.camera = 'off';
                    this.isSubmitting = true;
                    const response = await axios.get('/store/qrscan/' + decodedString);

                    const result = response.data;

                    if (result.status == 1)
                        throw result.message;

                    this.transaction = result.data.transaction;
                    this.storeInfo = result.data.store;
                    
                    this.showDiscountList();
                } catch (e) {                    
                    console.log(e);
                    this.$store.commit('setPopup', {
                        msg: this.lang(e) ? this.lang(e) : e,
                        type: this.popup_type.ERROR
                    });
                } finally {
                    this.isSubmitting = false;
                    this.camera = oldCamera;
                }
            }
        },
        onCameraChange (promise) {
            promise.catch(error => {
                const cameraMissingError = error.name === 'OverconstrainedError'
                const triedFrontCamera = this.camera === 'front'

                if (triedFrontCamera && cameraMissingError) {
                    // no front camera on this device
                    this.camera = 'auto';
                }
            })
        },
        switchCamera() {
            if (this.camera === 'front')
                this.camera = 'rear';
            else if (this.camera === 'rear')
                this.camera = 'front';
        },
        showDiscountList() {
            this.resultDialog = true;
        },
        showConfirmDialog() {
            this.confirmDialog = true;
            this.resultDialog = false;
        },
        phpDateTimeToDate(phpDateTime) {
            const date = new Date(phpDateTime);
            return date.getDate() + '/' + (date.getMonth() + 1) + '/' + date.getFullYear();
        },
        phpDateTimeToTime(phpDateTime) {
            const date = new Date(phpDateTime);
            return date.getHours() + ':' + date.getMinutes() + ':' + date.getSeconds();
        },
        phpDateDiffInMins(phpDateTime) {
            const InDate = new Date(phpDateTime);
            const now = new Date();
            return Math.floor(((now - InDate)/1000)/60);
        },
        cancelStamp() {
            this.resultDialog = false;
            this.confirmDialog = false;
            this.transaction = null;
            this.storeInfo = null;
            this.selectedPromotion = 0;
            this.selectedReceiver = 'customer';
            this.note = '';
        },
        async confirmStamp() {
            this.setLoading(true);
            try {                
                const response = await axios.post('/store/stamp/' + this.transaction.id, {
                    promotion_discount: this.selectedPromotion,
                    promotion_type: this.selectedReceiver,
                    promotion_note: this.note
                });

                const result = response.data;

                if (result.status == 1)
                    throw result.message;

                this.cancelStamp();

                this.$store.commit('setPopup', {
                    msg: 'success',
                    type: this.popup_type.SUCCESS
                });
            } catch (e) {
                this.$store.commit('setPopup', {
                    msg: this.lang(e) ? this.lang(e) : e,
                    type: this.popup_type.ERROR
                });
            } finally {
                this.setLoading(false);
            }


        }
    },
    components: {
        QrcodeStream,
        QrcodeDropZone,
        QrcodeCapture
    }
}
</script>