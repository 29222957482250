<template>
    <div
        :class="['d-flex', 'flex-column', 'flex-grow-1', 'justify-center', 'align-center', 'text--secondary', {'primary': isSelected}]"
        @click="$emit('click')"
        style="cursor: pointer;"
    >  
        <v-icon  
            :class="['text--darken-2', {'white--text': isSelected}]"          
            large
        >mdi-{{icon}}</v-icon>
        <h4
            :class="[{'white--text': isSelected}]"
        >{{text}}</h4>
    </div> 
</template>
<script>
export default {
    props: {
        icon: {
            type: String,
            required: true,
            default: ''
        },
        isSelected: {
            type: Boolean,
            default: false
        },
        text: {
            type: String,
            default: ''
        }
    }
}
</script>

<style>

</style>