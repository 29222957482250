<template>
  <v-app
    style="min-heght:100vh"
  >
    <v-main>
      <router-view/>
    </v-main>
    <v-dialog
      v-model="$store.state.showPopup"
      transition="dialog-bottom-transition"
      max-width="600"      
    >
      <v-card>
        <v-card-title 
          :class="['text-h5', 'darken-2', getPopupColor()]"          
        >
          {{ lang($store.state.popup.type) }}
        </v-card-title>

        <v-card-text
          class="mt-4 text-h6"
          v-if="$store.state.popup.type !== popup_type.SUCCESS"
        >
          {{ $store.state.popup.msg }}
        </v-card-text>
        <v-card-text
          class="mt-4 text-center"
          v-else
        >
          <v-icon
            style="font-size: 120px"
            color="success"
          >mdi-check-circle</v-icon>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue"
            large
            @click="clearPopup"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-overlay 
      :value="isLoading"
      style='z-index:10001;'
    >
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import login from './views/Login.vue';
export default {
  name: 'App',

  data: () => ({
    //
  }),
  computed: {
    isLogin() {
      return this.$store.getters['isLogin'];
    },
    isLoading() {
      return this.$store.getters['isLoading'];
    }
  },
  components: {
    login
  }
};
</script>
