<template>
    <v-container>
        <v-row class="secondary lighten-1 primary--text font-weight-bold">
            <v-col>
                <v-icon large color="primary" class="mr-2">mdi-text-box</v-icon>{{ lang("footer_menu_history") }}        
            </v-col>
        </v-row>
        <v-row>
            <v-col class="text-right">
                <h3>{{now}}</h3>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card
                    color="secondary lighten-1"
                >
                    <v-container>
                        <v-row>
                            <v-col
                                cols="6"
                                sm="6"
                            >
                                <piechart                    
                                    v-if="chartData.datasets.length > 0"
                                    style="width: 100%"
                                    :chartData="chartData"
                                ></piechart>
                            </v-col>
                            <v-col
                                cols="6"
                                sm="6"
                            >
                                <v-container>
                                    <v-row>
                                        <v-col>
                                            <v-card class="text-center pa-2 primary--text">
                                                <h5>{{lang('total_granted')}}</h5>
                                                <h2>{{total_granted}}</h2>
                                            </v-card>
                                        </v-col>
                                        <v-col>
                                            <v-card class="text-center pa-2 primary--text">
                                                <h5>{{lang('total_hours')}}</h5>
                                                <h2>{{total_hours}}</h2>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                    <v-row v-if="chartData.datasets[0]">
                                        <v-col>
                                            <div style="height:40px">
                                                <v-avatar
                                                    :color="chartData.datasets[0].backgroundColor[0]"
                                                    size="30"
                                                    class="mr-2"
                                                ></v-avatar> {{ lang('promotion_select_option_customer') }} ({{ chartData.datasets[0].data[0] }})
                                            </div>
                                            <div style="height:40px">
                                                <v-avatar
                                                    :color="chartData.datasets[0].backgroundColor[1]"
                                                    size="30"
                                                    class="mr-2"
                                                ></v-avatar> {{ lang('promotion_select_option_staff') }} ({{ chartData.datasets[0].data[1] }})
                                            </div>
                                            <div style="height:40px">
                                                <v-avatar
                                                    :color="chartData.datasets[0].backgroundColor[2]"
                                                    size="30"
                                                    class="mr-2"
                                                ></v-avatar> {{ lang('promotion_select_option_manager') }} ({{ chartData.datasets[0].data[2] }})
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-data-table
                                :headers="headers"
                                :items="transactions"                                
                                class="elevation-1"
                                style="width:100%"
                            >
                                <template v-slot:item.signInTime="{ item }">
                                    {{ convertPHPDateToJavascript(item.signInTime) }}                               
                                </template>

                                <template v-slot:item.promotion_type="{ item }">
                                    {{ item.isStorePay ? lang('promotion_discount_free_all_day') : lang('promotion_discount_free_mns', [item.promotion_discount]) }}                                                   
                                </template>

                                <template v-slot:item.promotion_discount="{ item }">
                                    {{ discountInHours(item.promotion_discount) }}                               
                                </template>
                            </v-data-table>
                        </v-row>
                    </v-container>
                </v-card>                
            </v-col>
        </v-row>        
    </v-container>
</template>
<script>
import axios from 'axios';
import piechart from '../components/piechart.js'
import infocard from '../components/infocard.vue';
export default {
    data() {
        return {
            chartData: {      
                labels: [],                    
                datasets: [],                
            },
            total_granted: 0,
            total_hours: 0,
            now: '',
            transactions: [],
            headers: [
                {
                    text: this.lang('in_date'),
                    align: 'start',
                    sortable: false,
                    value: 'signInTime',
                },
                {
                    text: this.lang('plate_no'),
                    align: 'start',
                    sortable: false,
                    value: 'plateNo',
                },
                {
                    text: this.lang('discount'),
                    align: 'start',
                    sortable: false,
                    value: 'promotion_type',
                },
                {
                    text: this.lang('hours'),
                    align: 'start',
                    sortable: false,
                    value: 'promotion_discount',
                },
            ],
        }
    },
    async created() {
        this.setLoading(true);
        const response = await axios.get('store/history');

        const data = response.data;

        this.chartData = data.chartData;        
        this.total_granted = data.total;
        this.total_hours = Math.floor(data.sum / 60);
        this.transactions = data.transactions;
        this.now = data.now;

        this.setLoading(false);        
    },
    methods: {
        convertPHPDateToJavascript(phpDateTime) {
            const date = new Date(phpDateTime);            
            return date.getDate().toString().padStart(2, '0') + '/' 
            + (date.getMonth() + 1).toString().padStart(2, '0') + '/' 
            + date.getFullYear() 
            + ' ' 
            + date.getHours().toString().padStart(2, '0') + ':'
            + date.getMinutes().toString().padStart(2, '0') + ':'
            + date.getSeconds().toString().padStart(2, '0')
            ;
        },
        discountInHours(minutes) {
            return (minutes / 60).toFixed(2);
        },
        discountToText(promotion_type) {
            return this.lang('promotion_discount_free_mns', [promotion_type])            
        }
    },
    components: {
        piechart,
        infocard
    }
}
</script>