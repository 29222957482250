import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex);

const popup_type = {
  ERROR: 'popup_error',
  INFO: 'popup_info',
  CONFIRM: 'popup_confirm',
  SUCCESS: 'popup_success'
};

export default new Vuex.Store({
  state: {
    token: null,
    showPopup: false,
    popup: {
      msg: null,
      type: null
    },
    user: {
      username: '',
      name: '',
      store: '',
      type: 'staff'
    },
    loading: false,
    reset_username: null,
    reset_token: null
  },
  getters: {
    isLogin (state) {
        if (state.token === null)
            return false;
        return true;
    },
    isLoading (state) {
      return state.loading;
    },
    isPopedUp(state) {
      if (state.popup.msg !== null && state.popup.type !== null)
        return true;
      return false;
    },
    isAdmin(state) {
      if (state.user.type === 'owner')
        return true;
      return false;
    },
    getPopupColor(state) {
      if (state.popup.type === popup_type.ERROR) {
        return 'error white--text';
      } else if (state.popup.type === popup_type.CONFIRM) {
        return 'warning';
      } else if (state.popup.type === popup_type.SUCCESS) {
        return 'success white--text';
      } else {
        return 'primary';
      }
    }
  },
  mutations: {
    clearPopup(state) {
      state.popup = {
        msg: null,
        type : null
      }

      state.showPopup = false;
    },
    setPopup(state, {msg, type}) {
      state.popup = {
        msg: msg,
        type: type
      };

      state.showPopup = true;
    },
    setLoginProfile(state, token) {
      const decoded = Vue.$jwt.decode(token);
      state.user = {
        username: decoded.username,
        name: decoded.name,
        store: decoded.store,
        type: decoded.type
      };     
      state.token = token;
      axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;
    },
    clearLoginProfile(state) {
      state.user = {
        username: '',
        name: '',
        store: '',
        type: 'staff'
      };
      state.token = null;
      axios.defaults.headers.common['Authorization'] = null;
    },
    setLoading(state, bool) {
      state.loading = bool;
    }
  },
  actions: {
    async login({ state, commit }, {username, password}) {
      try {
        const response = await axios.post('storeLogin', { username: username, password: password });
        const result = response.data;
        if (result.status == 1)
          throw result.message;

        commit('setLoginProfile', result.token);
        return true;
      } catch (e) {
        commit('setPopup', {
          msg: e == 'validation_wrong_username_password' ? "Username or Password is incorrect!" : e,
          type: popup_type.ERROR
        });
        return false;
      }
    },
    async logout({ commit }) {
      commit('clearLoginProfile');
    }
  },
  modules: {
  }
})
