<template>
    <v-card min-height="100">
        <div class="d-flex" style="height:100%">
            <div class="d-flex justify-center align-center flex-column flex-grow-1 flex-shrink-1">
                <div class="text-center"><v-icon x-large color="secondary darken-1">mdi-{{icon}}</v-icon></div>
                <div class="text-center"><h4 class="primary--text text--darken-2">{{text}}</h4></div>
            </div>
            <div class="d-flex justify-center align-center flex-grow-1 flex-shrink-1">
                <h1 class="primary--text text--darken-2">{{counter}}</h1>
            </div>
        </div>        
    </v-card>
</template>
<script>
export default{    
    props: {
        text: {
            type: String,
            required: true,
            default: ''
        },
        icon: {
            type: String,
            required: true,
            default: ''
        },
        counter: {
            type: [Number, String],
            required: true,
            default: 0
        }
    }
}
</script>
