import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import QRScanner from '../views/QRScanner.vue'
import Spaces from '../views/Spaces.vue'
import History from '../views/History.vue'
import Settings from '../views/Settings.vue'
import ForgetPassword from '../views/ForgetPassword.vue'
import PIN from '../views/PIN.vue'
import ResetPassword from '../views/ResetPassword.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    meta: {
      auth: true
    },
    children: [
      {
        // UserProfile will be rendered inside User's <router-view>
        // when /user/:id/profile is matched
        path: 'Home',
        name: 'Home',
        component: Home,
        meta: {
          auth: true
        },
      },
      {        
        path: 'QRScanner',
        name: 'QRScanner',
        component: QRScanner,
        meta: {
          auth: true
        },
      },
      {        
        path: 'Spaces',
        name: 'Spaces',
        component: Spaces,
        meta: {
          auth: true
        },
      },
      {        
        path: 'History',
        name: 'History',
        component: History,
        meta: {
          auth: true
        },
      },
      {        
        path: 'Settings',
        name: 'Settings',
        component: Settings,
        meta: {
          auth: true
        },
      }      
    ]
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {        
    path: '/ForgetPassword',
    name: 'ForgetPassword',
    component: ForgetPassword,        
  },
  {        
    path: '/PIN',
    name: 'PIN',
    component: PIN,        
  },
  {        
    path: '/ResetPassword',
    name: 'ResetPassword',
    component: ResetPassword,        
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
