<template>
    <div
        class="d-flex"
        style="background-color:#06B4B5;min-height:100vh"
    >
        <v-container
            style="max-width: 360px;"
            class="mt-auto mb-auto"
        >
            <v-row>
                <v-col>
                    <v-img
                    contain
                    height="100%"
                    max-height="50px"
                    src="@/assets/images/logo.png"></v-img>
                </v-col>
            </v-row>
            <v-row>
            </v-row>
            <v-row>
                <v-col>
                    <v-form
                        ref="loginForm"
                        v-model='isValid'
                    >
                        <v-container>
                            <v-row>
                                <v-col>
                                    <v-text-field
                                        v-model="username"
                                        :label="lang('Username')"
                                        color="white"
                                        background-color="#06c6c6"
                                        :rules="validationRules.username"
                                        required
                                    ></v-text-field>

                                    <v-text-field
                                        v-model="password"
                                        :label="lang('Password')"
                                        color="white"
                                        background-color="#06c6c6"
                                        type="password"
                                        :rules="validationRules.password"
                                        required
                                    ></v-text-field>

                                    <v-btn
                                        block
                                        color="primary darken-2"
                                        elevation="2"
                                        rounded
                                        x-large
                                        :disabled="!isValid"
                                        :loading="isLoading"
                                        @click="submit"
                                    >{{ lang('Login') }}</v-btn>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col>
                                    <router-link :to="{name: 'ForgetPassword'}" class="white--text">{{ lang('forget_password') }}</router-link>                                    
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-form>
                </v-col>
            </v-row>
            <v-row class="align-right">
                <v-btn
                :class="['ml-auto', {'blue': currentLang == 'en'}]"
                text
                @click="setLang('en')"
                >ENG            
                </v-btn>
                <v-btn
                :class="[{'blue': currentLang == 'th'}]"
                text
                @click="setLang('th')"
                >ไทย
                </v-btn>
            </v-row>
        </v-container>
    </div>
</template>
<script>
export default {
    data() {
        return {
            username: '',
            password: '',
            isValid: true,
            isLoading: false,
            validationRules: {
                username : [
                    v   =>  !!v || this.lang('username_validation')
                ],
                password : [
                    v   =>  !!v || this.lang('password_validation')
                ],
            }
        }
    },
    methods: {
        async submit() {
            this.isLoading = true;
            const loginResult = await this.$store.dispatch('login', { username : this.username, password : this.password });
            this.password = '';
            if (loginResult) {
                this.$router.push({name: 'Home'});
            }            
            this.isLoading = false;
        }
    }
}
</script>